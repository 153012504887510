<template>
  <div class="tags-input flex flex-row flex-wrap gap-1" 
  
  tabindex="0"
  v-bind="$attrs"
  @focus="input_selected"
  >

    <template v-for="tag of tags" :key="tag">
      <span class="letter-tile" @click="tag_action(tag)" :ref="`tag-${tag}`">
        {{ tag }}
      </span>
    </template>
    <input ref="input" type="text" 
      class="h-10 focus:placeholder-transparent"
      :class="tags.length > 0 ? 'w-1' : ''"
      @keydown="test_input"
      :placeholder="tags.length > 0 ? '' : 'Type here'"
 />
  </div>  
</template>

<script>
export default ({
  name: "TagsInput",
    model: {
      prop: 'modelValue',
      event: 'update-model'
    },
  props: {
    modelValue: Array,
  },
  data() {
    return {
      tags: [],
    }
  },
  watch: {
    modelValue(new_model_value) {
      this.tags = new_model_value;
    },
    tags() {
      // Wheneer this.tags changes, update the (v-)model 
      this.$emit('update-model', this.tags)
    }
  },
  methods: {
    test() {
      console.log('enter detected')
    },
    input_selected() {
      this.$refs.input.focus();
    },
    test_input(e) {
      e.preventDefault();
      // Enforce lower case
      let K = e.key.toLowerCase(); 
      if (this.$alphabet.is_alpha(K)) {
        // Validate
        if (!this.tags.includes(K)) {
          // Check if already exists
          // DNE
          this.add_tag(K);
        } else { 
          this.shake_tag(K);
        }
      }
      if (K == "backspace") {
        this.delete_tag();
      }

      return false;

    },
    shake_tag(L) {
      let tag_to_shake = this.$refs[`tag-${L}`][0];
          
      tag_to_shake.classList.add("already-added");
      setTimeout(() => {
        tag_to_shake.classList.remove("already-added");
      }, 1000)
    },
    add_tag(L) {
      this.tags.push(L);
    },
    delete_tag() {
      this.tags.pop();
    },
    clear() {
      this.tags = [];
    },
    tag_action(L) {
      let mark_delete_tag_index = this.tags.indexOf(L);
      this.tags.splice(mark_delete_tag_index, 1);
    } 
  }
})
</script>

<style lang="scss" scoped>
.letter-tile {
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  @apply font-medium;

  @apply text-base;
  @apply h-10;
  @apply w-10;

  @apply border;
  @apply rounded-md;
  @apply border-gray-200;
  @apply dark:border-gray-800;
  @apply flex;
  @apply place-content-center;
  @apply items-center;

  &:hover {
    border: 1px solid var(--primary-border-hover-color);
  }

}

.already-added {
  border: 1px solid var(--primary-border-hover-color);
  animation: shake 0.9s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(0, -1px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 1px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -2px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 2px, 0);
  }
}

// @keyframes shake {
//   10%, 90% {
//     transform: translate3d(-2px, 0, 0);
//   }
  
//   20%, 80% {
//     transform: translate3d(2px, 0, 0);
//   }

//   30%, 50%, 70% {
//     transform: translate3d(-3px, 0, 0);
//   }

//   40%, 60% {
//     transform: translate3d(3px, 0, 0);
//   }
// }

.tags-input {
  @apply w-full;
  @apply p-2;
  @apply rounded-lg;
  cursor: text;
  border: 1px solid var(--primary-border-color);
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px 0px var(--translucency);

  input {
    border: none;
    outline: none;
    background-color: transparent;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.tags-input:hover {
  border: 1px solid var(--primary-border-hover-color);
}

.tags-input:focus,
.tags-input:focus-within {
  border: 1px solid var(--primary-border-hover-color);
  box-shadow: 0px 0px 0px 4px var(--translucency);
}
</style>