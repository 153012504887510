<template>
  <div id="app">
    <router-view></router-view> 
  </div>
</template>

<script>

export default {
  name: 'app',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
          document.title = "Wordle Dictionary";
      }
    },
  },
  metaInfo: {
    script: [
      { src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8921611818089652', async: true, defer: true }
    ],
  }
}
</script> 

<style lang="scss">
// @import './assets/styles/variables.css';

#app {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

body {
  @apply bg-gray-50;
  @apply text-black;
}

body.dark {
  @apply bg-gray-950;
  @apply text-gray-50;
}

a {
  text-decoration: none;
  @apply text-blue-600;
  @apply hover:text-blue-800;
  @apply dark:text-blue-400;
  @apply dark:hover:text-blue-300;
}

</style>
