import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      results: [],
      results_metadata: [],
      results_letter_count: undefined,
      fixed_chars: [null, null, null, null, null],
      excluded_chars: new Set(),
      included_chars: new Set(),
      sort_mode: "ws",
    }
  },
  getters: {
    lookup_word: (state) => (word) => {
      return state.results.filter((result) => result.word == word)[0];
    },
    lookup_word_letter_freq: (state) => (letter) => {
      let freq_result = state.results_metadata.char_word_freq_table[letter];
      return freq_result ? freq_result : 0;
    },
    lookup_positional_letter_freq: (state) => (letter, pos) => {
      let freq_result = state.results_metadata.char_pos_freq_table[pos][letter];
      return freq_result ? freq_result : 0;
    },
    lookup_nc2g_freq: (state) => (nc2g) => {
      return state.results_metadata.NC2G_table[nc2g];
    },
    results(state) {
      return state.results;
    },
    results_metadata(state) {
      return state.results_metadata;
    },
    found_letters(state) {
      return state.fixed_chars;
    },
    included_letters(state) {
      return state.included_chars;
    },
    excluded_letters(state) {
      return state.excluded_chars;
    },
    is_letter_filtered: (state) => (letter) => {
      let all_filtered_letters =  Array.prototype.concat(
          Array.from(state.included_chars), 
          state.fixed_chars.filter(L => { if (L != null) return L })
        );
      if (all_filtered_letters.includes(letter)) {
        return true;
      }
      return false
    },
  },
  mutations: {
    set_inclusions: function(state, payload) {
      state.included_chars = payload;
    },
    add_inclusion: function(state, payload) {
      state.included_chars.add(payload);
    },
    remove_inclusion: function(state, payload) {
      state.included_chars.delete(payload);
    },
    clear_inclusions: function(state) {
      state.included_chars.clear();
    },
    set_exclusions: function(state, payload) {
      state.excluded_chars = payload;
    },
    add_exclusion: function(state, payload) {
      state.excluded_chars.add(payload);
    },
    remove_exclusion: function(state, payload) {
      state.excluded_chars.delete(payload);
    },
    clear_exclusions: function(state) {
      state.excluded_chars.clear();
    },
    set_fixed: function(state, payload) {
      let position = payload.position;
      let char = payload.char;
      state.fixed_chars[position] = char;
    },
    set_results: function(state, payload) {
      state.results = payload;
      // this triggers mutation reactivity 
      state.results.push(" ");
      state.results.pop();
    },
    clear_results: function(state, payload) {
      state.results = [];
    },
    set_results_metadata: function(state, payload) {
      state.results_metadata = payload;
      // this triggers mutation reactivity 
    },
    
    set_letter_count: function(state, payload) {
      state.results_letter_count = payload;
    },

    set_sort_mode: function(state, payload) {
      state.sort_mode = payload;
    }
   },
   actions: {
    search: async function( {commit, dispatch, state} ) {    

      const alpha_sort_fn = function(a, b){
        if (a < b) { return -1; 
        } else if (a > b) { return 1; 
        } else { return 0; }
      }

      let find, has, not, limit;

      find = state.fixed_chars.map(L => { return L ? L : '_'}).join('');

      has = Array.from(state.included_chars).sort(alpha_sort_fn).join();

      not = Array.from(state.excluded_chars).sort(alpha_sort_fn).join();
    
      const url = `/.netlify/functions/query?find=${find}&has=${has}&not=${not}&limit=${limit}`;
      try {
          const response = await fetch(url);
          const data = await response.json();

          commit("set_results", data.results);
          commit("set_results_metadata", data.results_metadata);

          dispatch('sort');
          return;
      } catch (err) {
          console.log(err);
          return;
      }
    },
    sort: async function( context ) {
      const temp_results = context.state.results.slice(); // slice to deep copy
      // why deep copy?
      // want to trigger only one mutation, not multiple per sorting and therefore multiple re-renderings

      const new_mode = context.state.sort_mode;
  
      if (new_mode == "a") {
        temp_results.sort(function(a, b){
          if (a.word < b.word) { 
            return -1; 
          } else if (a.word > b.word) { 
            return 1; 
          } else {
            return 0;
          }
        })
      } else if (new_mode == "ws") {
        temp_results.sort(function(a, b){
          if (a.score > b.score) { 
            return -1; 
          } else if (a.score < b.score) { 
            return 1; 
          } else {
            return 0;
          }
        });
      } else if (new_mode == "dv") {
        temp_results.sort(function(a, b){
          if (a.meta.vc > b.meta.vc) { 
            return -1; 
          } else if (a.meta.vc < b.meta.vc) { 
            return 1; 
          } else {
            return 0;
          }
        });
      } else if (new_mode == "dc") {
        temp_results.sort(function(a, b){
          if (a.meta.cc > b.meta.cc) { 
            return -1; 
          } else if (a.meta.cc < b.meta.cc) { 
            return 1; 
          } else {
            return 0;
          }
        });
      } else {
        console.warn("Don't be naughty.");
      }

      context.commit("set_results", temp_results);
    }
  },
});

export default store;