<template>
  <div class="search-container rounded-2xl border border-gray-200 bg-white dark:border-gray-900 dark:bg-gray-950 mx-2">
    <h1 class="text-lg font-medium">
      Search</h1>
    <div class="input-group">
      <p class="text-base text-gray-600 dark:text-gray-100">Found letters</p>
      <Letterboxes ref="Letterboxes"></Letterboxes>
    </div>
    <div class="input-group">
      <p class="text-base text-gray-600 dark:text-gray-100">Found letters without known locations</p>
      <TagsInput class="included_letters_tags_input" ref="included" v-model="included_l" @keydown.enter="search"></TagsInput>
    </div>
    <div class="input-group">

      <p class="text-base text-gray-600 dark:text-gray-100">Eliminated letters</p>
      <TagsInput class="excluded_letters_tags_input" ref="excluded" v-model="excluded_l" @keydown.enter="search"></TagsInput>
    </div>
    <div class="button-group">
      <VButton label="Clear" :action="reset"></VButton>
      <VButton label="Search" :action="search" importance="primary"></VButton>
    </div>
  </div>
</template>

<script>
import Letterboxes from "./Letterboxes.vue";
import TagsInput from "../TagsInput.vue";
import VButton from "../Button.vue";


const alpha_sort_fn = function(a, b){
  if (a < b) { return -1; 
  } else if (a > b) { return 1; 
  } else { return 0; }
}

export default {
  name: "SearchPanelV2",
  components: {
    Letterboxes,
    TagsInput,
    VButton,
  },
  data() {
    return {
      included_l: [],
      excluded_l: [],
    }
  },
  watch: {
    included_l(new_arr, old_arr) {
      let conv_new_arr = new Set(new_arr);
      this.$store.commit("set_inclusions", conv_new_arr);
    },
    excluded_l(new_arr, old_arr) {
      let conv_new_arr =  new Set(new_arr);
      this.$store.commit("set_exclusions", conv_new_arr);
    }
  },
  mounted() {
    this.included_l = Array.from(this.$store.getters.included_letters);
    this.excluded_l = Array.from(this.$store.getters.excluded_letters);
  },
  computed: {
  },
  methods: {
    reset: function() {
      this.$refs.Letterboxes.unset_all();
      this.$refs.included.clear();
      this.$refs.excluded.clear();

      return;
    },
    unset_keytile: function(char) {
      this.$refs.Keyboard.unset_one_keytile(char);
      return;
    },
    search: function() {
      // so letters in the query are sorted and pretty
      let query_obj = {
        find: [...this.$store.getters.found_letters].map((c) => {
          if (c) {
            return c;
          } else {
            return "_";
          }
        }).join(""),
        has: Array.from(this.$store.getters.included_letters).sort(alpha_sort_fn).join(),
        not: Array.from(this.$store.getters.excluded_letters).sort(alpha_sort_fn).join(),
      };
    
      // this.$router.push("results");
      this.$router.push({ 
        path: '/results', 
        query: query_obj,
      });
    },

  }
}
</script>

<style lang="scss">

.search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @apply p-6;
  @apply sm:p-8;
  margin: auto;

  .divider {
    width: 100%;
    height: 1px;
    background: var(--primary-border-color);
  }

  .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .input-group-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-family: $ff-sans-serif;

      h2 {
        color: var(--primary-text-color);
        font-weight: 500;
        font-size: var(--small-caps-fs);
        text-transform: uppercase;
        line-height: var(--small-caps-lh);
        letter-spacing: 0.1em;
      }

      p.subtitle {
        color: var(--secondary-text-color);
        font-size: var(--secondary-fs);
        font-weight: 600;
        line-height: var(--secondary-lh);
        text-align: center;
      }
    }
  }

  .included_letters_tags_input {
    .letter-tile {
      @apply text-white;
      background-color: var(--color-highlight);
    }
  }

  .excluded_letters_tags_input {
    .letter-tile {
      @apply text-white;
      @apply bg-gray-400;
      @apply dark:bg-gray-500;
    }
  }

  .button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--spacing-medium);
  }
}
</style>