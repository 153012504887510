<template>
  <button 
    :class="button_style"
    @click="action">
    {{ label }}
  </button>
</template>


<script>
export default {
  name: "VButton",
  props: {
    label: {type: String, required: true, },
    action: {type: Function, required: true, },
    importance: {type: String, required: false },
  },
  computed: {
    button_style: function() {
      if (this.importance == "primary") {
        return "primary"
      } else if (this.importance == "secondary") {
        return "secondary"
      } else {
        return "secondary"
      }
    }
  },
}
</script>



<style lang="scss" scoped>

button {
  border: none;
  border-radius: 8px;
  padding: 8px 16px;

  font-family: $ff-sans-serif;
  font-size: 14px;
  line-height: 18px;


  transition: all 0.5s ease;
  box-shadow: none;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02);
}

button.primary {
  color: var(--inverted-text-color);
  background: var(--inverted-background);
}

button.secondary {
  color: var(--primary-text-color);
  background: var(--background);
  border: 1px solid var(--primary-border-color);
}

@media(hover) {
  button:not(:disabled):hover {
    border-color: var(--primary-border-hover-color);
    transform: translate3d(0px, -2px, 0px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease;
  }
}
button:active {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.2s ease;
}
</style>
