<template>
  <div class="flex flex-col gap-4">
    <h1 class="text-xl font-bold">Insights</h1>
    <!-- How you're doing so far -->
    <div class="flex flex-col gap-2"> 
      <div><h2 class="text-sm font-medium">Progress</h2></div>
      <div><span class="text-4xl font-bold">{{ pct_words_already_eliminated }}%</span> of all possible answers eliminated</div>
    </div>
    <!-- Letters left -->
    <div class="flex flex-col gap-2">
      <div><h2 class="text-sm font-medium">Letters left in play</h2></div>
      <div class="flex flex-row flex-wrap gap-4 md:gap-4 ">
        <template v-for="letter of letters_left_arr" :key="letter">
          <div>
            <div class="letter-tile">  {{ letter.letter }}


              <div class="remaining-bubble">{{ letter.freq }}</div>

            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div><h2 class="text-sm font-medium">Letters eliminated or found</h2></div>
      <div class="flex flex-row flex-wrap gap-4 md:gap-4">
        <template v-for="letter of letters_eliminated_arr" :key="letter">
          <div>
            <div class="letter-tile">{{ letter }}</div>
          </div>
        </template>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analysis",
  props: {

  },
  data() {
    return {
      letters_left_arr: [],
      letters_eliminated_arr: [],
    }
  },
  computed: {
    pct_words_already_eliminated() {
      return Math.round((2315 - this.$store.getters.results.length) / 2315 * 1000) / 10;
    },
    letters_left() {
      return this.$store.getters.results_metadata.char_word_freq_table;
    },
  },
  mounted() {
    this.compute_letters_left();
  },
  methods: {
    compute_letters_left() {
      let eliminated_letters = new Set(this.$alphabet.all);

      for (const [L, freq] of Object.entries(this.letters_left)) {
        if (!this.$store.getters.is_letter_filtered(L)) {
          eliminated_letters.delete(L);
          this.letters_left_arr.push({ letter: L, freq: freq });
        }
      }

      this.letters_left_arr.sort(function(a, b){
          if (a.freq < b.freq) { 
            return 1; 
          } else if (a.freq > b.freq) { 
            return -1; 
          } else {
            return 0;
          }
        });

      this.letters_eliminated_arr = Array.from(eliminated_letters);
    }
  }
}
</script>

<style lang="scss" scoped>
.letter-tile {
  text-transform: uppercase;
  position: relative;

  @apply font-medium;

  @apply text-sm;
  @apply h-8;
  @apply w-8;

  @apply md:text-base;
  @apply md:h-10;
  @apply md:w-10;

  @apply border;
  @apply rounded-md;
  @apply border-gray-200;
  @apply dark:border-gray-800;
  @apply flex;
  @apply place-content-center;
  @apply items-center;
}

.remaining-bubble {
  @apply text-xs;
  @apply font-bold;
  @apply rounded-full;
  @apply text-white;
  @apply dark:text-black;
  @apply bg-gray-800;
  @apply dark:bg-gray-200;
  @apply px-2;
  @apply py-0;
  position: absolute;
  right: -8px;
  top: -8px;
}

h2 {
  color: var(--secondary-text-color);
}

</style>