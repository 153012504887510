<template>
  <div>
    
    <div 
      class="tooltip" 
      :class="tooltip_css"
      role="tooltip">
      {{ tooltip_text }}
      <div class="arrow"></div>
    </div>
    <div 
      class="keytile" 
      tabindex="0"
      aria-describedby="tooltip"
      :class="state_css"
      @click.exact="toggle"
      @keydown.enter="toggle"

      @mouseenter="show_tooltip"
      @mouseleave="hide_tooltip"
      @blur="hide_tooltip"
      >
      <div class="keytile-inner">
        {{ key_char }}
      </div>
    </div>
  </div>
</template>

<script>
import { computePosition, shift, offset, arrow } from "@floating-ui/dom";

export default {
  name: 'Keytile',
  props: {
    key_char: {type: String, required: true},
    enabled: {type: Boolean, required: true},
    parent: Object,
    store: Object,
  },
  data: function() {
    return {
      include: false,
      exclude: false,
      hover: false,
      button: undefined,
      tooltip: undefined,
      tooltip_state: false,
    }
  },
  created: function() {
    this.$store = this.store;
  },
  mounted: function() {

    this.button = this.$el.querySelector(".keytile");
    this.tooltip = this.$el.querySelector(".tooltip");

    if (this.$store.state.included_chars.has(this.key_char)) {
      this.set_included(this.key_char);
    }

    if (this.$store.state.excluded_chars.has(this.key_char)) {
      this.set_excluded(this.key_char);
    }
  },
  watch: {
    hover: function() {
    }
  },
  computed: {
    state_css: function() {
      if (this.include) {
        return "keytile-state-included"
      } else if (this.exclude) {
        return "keytile-state-excluded"
      } else {
        return "keytile-state-default"
      }
    },
    tooltip_css: function() {
      if (this.tooltip_state == true) {
        return "tooltip-visible";
      } else {
        return "tooltip-hidden";
      }
    },
    tooltip_text: function() {
      // const c = this.key_char.toUpperCase();
      if (this.include) {
        return `Finding`;
      } else if (this.exclude) {
        return `Eliminating`;
      } else {
        return `Nothing`;
      }
    }
  },
  methods: {
    show_tooltip: function() {
      const arrow_element = this.tooltip.querySelector('.arrow');

      this.tooltip_state = true;
      computePosition(this.button, this.tooltip, {
        placement: 'top',
        middleware: [shift({padding: 4}), offset(6), arrow({element: arrow_element}),],
      }).then(({x, y, placement, middlewareData}) => {
        Object.assign(this.tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const {x: arrowX, y: arrowY} = middlewareData.arrow;

        const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[placement.split('-')[0]];
        
        Object.assign(arrow_element.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });
      });
    },
    hide_tooltip: function() {
      this.tooltip_state = false;
    },
    toggle: function(e) {
      if (this.enabled) {
        e.preventDefault();
        // states: unset -> exclude -> include
        if (!this.include && !this.exclude) {
          this.set_included(this.key_char);
        } else if (this.include) {
          this.set_excluded(this.key_char);
        } else if (this.exclude) {
          this.unset();
        } else {
          // if something goes weird
          this.unset();
        }

        // this forces the tooltip to recalculate its position
        this.$nextTick(() => {
          this.show_tooltip();
        })
      }
    },
    set_included: function(char) {
      this.exclude = false;
      this.include = true;
      this.$store.commit("remove_exclusion", char);
      this.$store.commit("add_inclusion", char);
    },
    set_excluded: function(char) {
      this.exclude = true;
      this.include = false;
      this.$store.commit("remove_inclusion", char);
      this.$store.commit("add_exclusion", char);
    },
    unset: function() {
      this.include = this.exclude = false;
      this.$store.commit("remove_exclusion", this.key_char);
      this.$store.commit("remove_inclusion", this.key_char);
    }
  },
}
</script>

<style lang="scss">

.keytile {
  border-radius: 4px;
  width: var(--keytile-w);
  height: var(--keytile-w);
  cursor: pointer;
  outline: none;
}

.keytile .keytile-inner {
  color: var(--primary-text-color);
  border: 1px solid var(--primary-border-color);
  border-radius: 4px;
  box-sizing: border-box;

  box-shadow: inset 0px -2px 0px var(--inset-shadow);

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  font-family: $ff-sans-serif;
  font-size: var(--keytile-fs);
  line-height: var(--keytile-lh);
  text-transform: uppercase;
  
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  transition: border 0.3s ease;
}

.keytile .keytile-inner:hover {
  border: 1px solid;
  border-color: var(--primary-border-hover-color);
}


.keytile-state-default {
  .keytile-inner {
    background: var(--background);
  }
}

.keytile-state-included {
  // background: black;
  background: #6aaa64;

  .keytile-inner {
    border: 1px solid var(--color-include);
    color: white;
  }
}


.keytile-state-excluded {
  box-shadow: none;

  .keytile-inner {
    background: var(--secondary-background);
    color: var(--tertiary-text-color);
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


</style>
