<template>
  <div class="relative bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-900 h-48 sm:h-64 rounded-2xl drop-shadow-md">
    <div class="animate-pulse  absolute top-2 left-2">
      <div class="word-score px-2 rounded-full bg-gray-800 dark:bg-gray-100 h-4 w-8">
      </div>
    </div>

    <div class="animate-pulse  word-title absolute top-1/2 left-1/2 word-centered">
      <span class="word rounded-full bg-black dark:bg-white h-4 w-24">

      </span> 
    </div>

    <div class="animate-pulse  absolute bottom-4 left-4">
      <div class="word-meta flex flex-col gap-1">
        <div class="bg-gray-500 rounded-full h-2 w-16"></div>
        <div class="bg-gray-500 rounded-full h-2 w-16"></div>
      </div>
    </div>

  </div> 
</template>

<script>
export default {
  name: "Word",
}
</script>

<style lang="scss" scoped>

  .flow-block {
    font-family: "Flow Block", sans-serif;
  }

  .word-centered {
    transform: translate(-50%,-50%);
  }

  .result {
    width: 100%;
    padding: 16px;
  }

  .word-title {
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .word {
    font-weight: 500;
    text-transform: capitalize;
  }

  mark {
    color: inherit;
    background-color: var(--color-highlight);
  }

  .word-score {
    display: flex;
    align-items: center;
    gap: 4px;

    font-weight: 600;
    // color: var(--primary-text-color);
    // background-color: var(--tertiary-background);
    // font-size: var(--primary-fs);
    // line-height: var(--primary-lh);

    cursor: pointer;
  }

  .word-meta {
  }
</style>