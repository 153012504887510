import Vue from "vue";
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import VueGtag from "vue-gtag"
import './scss/tailwind.scss'

import store from './store.js';


import App from './App.vue'
import Search from './views/Search.vue'
import SearchV2 from './views/Search_V2.vue'
import Results from './views/Results.vue'
import './assets/tailwind.css'

import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'


import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})



const routes = [
  { name: "Search", path: '/', component: SearchV2 },
  // { name: "SearchV2", path: '/v2/', component: SearchV2 },
  { name: "Results", path: '/results/', component: Results, 
    props: route => ({ 
      find: route.query.find,
      has: route.query.has,
      not: route.query.not,
     }) },
  // ...generated_routes,
  // temporarily disable SEO pages
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

// Instantiate the Vue app
const app = createApp({
  store,
  router,
  render: h => h(App),
});

// Analytics
app.use(VueGtag, {
  appName: 'Wordle Dictionary',
  pageTrackerScreenviewEnabled: true,
  config: { id: "G-01VYW3T7GS" }
}, router);

// Adsense stuff
app.use(ScriptX)
app.use(Ads.Adsense)
app.use(Ads.InArticleAdsense)
app.use(Ads.InFeedAdsense)

app.use(router);
app.use(store);
app.mount('#app');
app.config.globalProperties.$alphabet = {
  all: new Set(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]),
  vowels: new Set(["a", "e", "i", "o", "u"]),
  consonants: new Set(["b", "c", "d", "f", "g", "h", "j", "k", "l", "m", "n", "p", "q", "r", "s", "t", "v", "w", "x", "y", "z"]),
  is_alpha: function(char) {
    return this.all.has(char.toLowerCase()) ? true : false;
  },
  is_vowel: function(char) {
    return this.vowels.has(char.toLowerCase()) ? true : false;
  },
  is_consonant: function(char) {
    return this.consonants.has(char.toLowerCase()) ? true : false;
  }
}

// check to see if user has dark mode enabled
// initial setting
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.body.classList.add("dark");
}
// listen for change
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function() { 
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }
})