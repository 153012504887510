<template>
  <div v-show="visible" class="modal-container">
    <div class="modal-backdrop bg-gray-100 dark:bg-gray-900 bg-opacity-70 dark:bg-opacity-70" ref="modal_backdrop"  @click="hide"> 
      <div class="modal-outer bg-white dark:bg-gray-800 bg-opacity-70 dark:bg-opacity-70" @click.stop>
        <div class="modal-inner bg-white dark:bg-gray-950 border border-gray-100 dark:border-gray-900">
          <div class="modal-content">
            <slot></slot>
          </div>
          <div class="close-modal-btn-group">
          <VButton class="close-modal-btn" label="Close" :action="hide"></VButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from './Button.vue';

export default {
  name: "Modal",
  components: {
    VButton,
  },
  data: function() {
    return {
      visible: false,
    }
  },
  methods: {
    hide: function() {
      this.visible = false;
    },
    show: function() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.modal_backdrop.scrollTo(0, 0); 
      })
    }
  }
}
</script>

<style lang="scss">

.modal-backdrop {
  backdrop-filter: blur(2px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  overflow: scroll;
  @apply py-4;
}

.modal-outer {
  z-index: 999;
  // position: fixed;
  // top: 50vh;
  // left: 50%;
  // transform: translate(-50%,-50%);
  max-width: 480px;
  width: calc(100% - 16px);

  padding: 8px;
  border-radius: 8px;

  margin: auto;
  backdrop-filter: blur(2px);
}

.modal-inner {
  padding: 20px;
  border-radius: 8px;
}

.modal-content {
  @apply sm:text-base;
  @apply text-sm;
}

.close-modal-btn-group {
  display: flex;
  align-items: center;
  margin-top: 16px;

  .close-modal-btn {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>