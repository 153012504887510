<template>
  <input 
    class="letter-box focus:placeholder-transparent"
    :class="letter_box_css"
    type="text"
    maxlength="1"
    tabindex="0"
    placeholder="?"
    @input="on_input"
    @keydown="on_keydown"
    @keyup="on_keyup"
    @keydown.enter="search_proxy"
    v-model="char" />
</template>

<script>

export default {
  name: "Letterbox",
    data: function() {
    return {
      char: "",
      position_index: undefined,
      first: false,
      last: false,
      letter_box_css: "",
    }
  },
  watch: {
    char: function(newChar) {
      newChar = newChar.toLowerCase();
      if (this.$alphabet.is_alpha(newChar)) {
        this.$store.commit("set_fixed", {
          position: this.position_index,
          char: newChar,
        });

        // contradicts an exclusion filter
        if (this.$store.state.excluded_chars.has(newChar)) {
          this.$parent.$parent.unset_keytile(newChar);
        }

        // exceptional: here we manipulate element css directly w/o state
        this.letter_box_css = "letter-box-filled";
      } else {
        this.$store.commit("set_fixed", {
          position: this.position_index,
          char: null,
        });
        this.letter_box_css = "";
      }
    }
  },
  created: function() {
    this.position_index = this.$parent.$children.indexOf(this);
  },
  mounted: function() {
    if (this.$store.state.fixed_chars[this.position_index]) {
      this.char = this.$el.value = this.$store.state.fixed_chars[this.position_index];
    }
  },
  methods: {
    unset: function() {
      this.$el.value = "";
      this.char = "";
    },
    search_proxy: function(e) {
      e.preventDefault();
      this.$parent.$parent.search();
    },
    on_keydown: function(e) {

      // reject input that is not alpha or command
      let evt_char = e.key.toLowerCase();
      let COMMANDS = new Set(["backspace", "delete", "arrowleft", "arrowright", "enter", "tab"]);
      let valid_input = new Set([...this.$alphabet.all, ...COMMANDS]);

      if (!valid_input.has(evt_char)) e.preventDefault();
    },
    on_input: function(e) {
      if (e.data) {
        const is_last = (this.position_index == this.$parent.$children.length - 1) ? true : false;
        let evt_char = e.data.toLowerCase();
        if (this.$alphabet.is_alpha(evt_char)) {
          
          
          if (!is_last) {
            this.$el.nextElementSibling.focus();
            // this.$el.nextElementSibling.select();
          }
        }
      }
    },
    on_keyup: function(e) {
      const is_first = (this.position_index == 0) ? true : false;
      const is_last = (this.position_index == this.$parent.$children.length - 1) ? true : false;
      let evt_char = e.key;
      if (evt_char == "ArrowLeft" && !is_first) {
          this.$el.previousElementSibling.focus();
          // this.$el.previousElementSibling.select();
      }
      if ((evt_char == "ArrowRight" || evt_char == " ") && !is_last) {
          this.$el.nextElementSibling.focus();
          // this.$el.nextElementSibling.select();
      }
      if ((evt_char == "Backspace" || evt_char == "Delete") && !is_first) {
        this.$el.previousElementSibling.focus();
        // this.$el.previousElementSibling.select();
      }
    },
    search: function() {
      // app.$refs.search.search();
    },
  }
}
</script>

<style lang="scss" scoped>

.letter-box {
  font-family: $ff-sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  background: inherit;
  border: 1px solid var(--primary-border-color);
  box-sizing: border-box;
  border-radius: 8px;

  font-size: 20px;
  @apply h-12;
  @apply w-12;

  @apply lg:h-16;
  @apply lg:w-16;
  text-align: center;
  vertical-align: middle;

  -webkit-touch-callout: none !important;
  -webkit-appearance: none;

  box-shadow: 0px 0px 0px 0px var(--translucency);
  transition: all 0.3s ease;

}

.letter-box-filled {
  color: var(--white);
  background: var(--color-include);
}

@media(hover) {
  .letter-box:hover {
    border: 1px solid;
    border-color: var(--primary-border-hover-color);
  }
}

.letter-box:focus {
  outline: 0px none #46474D;

  border: 1px solid;
  border-color: var(--primary-border-hover-color);
  box-shadow: 0px 0px 0px 4px var(--translucency);
}

</style>