<template>
  <div class="Letterbox-row">
    <Letterbox></Letterbox>
    <Letterbox></Letterbox>
    <Letterbox></Letterbox>
    <Letterbox></Letterbox>
    <Letterbox></Letterbox>
  </div>
</template>

<script>
import Letterbox from "./Letterbox.vue";

export default {
  name: "Letterboxes",
  components: {
    Letterbox,
  },
  methods: {
    unset_all: function() {
      for (const letterbox of this.$children) {
        letterbox.unset();
      }
    },
  }
}
</script>

<style scoped>

.Letterbox-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

</style>