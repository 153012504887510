<template>
<div>
  <template v-if="loading_state">
    <div class="grid gap-2 grid-cols-2 px-2 pb-4">
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
      <WordLazyLoader></WordLazyLoader>
    </div>
  </template>
  <template v-else>
    <template v-if="sorted_results.length > 0" >
      <div class="grid gap-2 grid-cols-2 pb-4">
        <template v-for="result in sorted_results" :key="result.w">

          <Word 
            @open-score-modal="open_score_modal"
            :word="result"></Word>

        </template>
      </div>
    </template>
    <template v-else>
      <div class="pb-4">
        No results found. Please double check if you had entered a wrong letter.
      </div>
    </template>
  </template>
</div>
</template>

<script>
import Word from "./Word.vue";
import WordLazyLoader from "./WordLazyLoader.vue";

export default {
  name: "WordList", 
  components: {
    Word,
    WordLazyLoader,
  },
  computed: {
    sorted_results: function() {
      return this.$store.state.results;
    },
    loading_state: function() {
      return this.$parent.loading_state;
    }
  },
  props: {
    results: Array,
    sort_mode: String,
  },
  methods: {
    open_score_modal(data) {
      this.$parent.selected_word = data.word;
      this.$parent.$refs.word_analysis_modal.show();
      // let store_obj = this.$store;
      // var ModalApp = createApp({  
      //   setup() {
      //     return () => h(WordModal, {selected_word: data, store: store_obj})
      //   }
      // });
      // const wrapper = document.createElement("div")
      // ModalApp.mount(wrapper)
      // document.body.appendChild(wrapper);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>