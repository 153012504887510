<template>
  <div class="relative bg-white dark:bg-gray-900 border border-gray-200 transition-all hover:border-gray-900 dark:hover:border-gray-200 dark:border-gray-900 h-48 sm:h-64 rounded-2xl drop-shadow-md cursor-pointer" @click="$emit('open-score-modal', word)">
    <div class="absolute top-2 left-2">
      <div class="word-score px-2 rounded-full">
        <div>
          <p class="text-gray-400 dark:text-gray-600 text-xs font-semibold">Guess Power</p>
          <p class="text-black dark:text-white sm:text-xl text-lg">{{ score_percentage }}<span class="text-opacity-50">%</span></p>
        </div>
      </div>
    </div>

    <div class="word-title absolute top-1/2 left-1/2 word-centered">
      <span class="word text-black dark:text-white sm:text-3xl text-2xl">
        <span v-html="highlight(word.word)"></span>
      </span> 
    </div>

    <div class="absolute bottom-4 left-4">
      <div class="word-meta">
        <p class="text-gray-500 text-sm">{{ text_vowels(word) }}</p>
        <p class="text-gray-500 text-sm">{{ text_consonants(word) }}</p>
      </div>
    </div>

    <div class="absolute bottom-5 right-5">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 p-1 rounded-full bg-gray-100 dark:bg-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
      </svg>
    </div>

  </div> 
</template>

<script>
const vowels = new Set(["a", "e", "i", "o", "u"]);

export default {
  name: "Word",
  components: {
    // Modal,
  },
  props: {
    word: Object,
  },
  data: function() {
    return {
      char_freq_table: [],
      NC2G_freq_table: [],
    }
  },
  computed: {
    score_percentage: function() {
      return Math.round(this.word.score / this.$store.state.results.length * 100);
    }
  },
  updated() {
    console.log('word updated')
  },
  methods: {
    open_word_score_modal: function() {
      
    },
    highlight(word) {
      word = word.split("");
      let chars_to_highlight = Array.prototype.concat(Array.from(this.$store.state.included_chars), this.$store.state.fixed_chars);

      for (let position_index = 0; position_index < word.length; position_index++) {
        if (position_index == 0) {
          // programmatically capitalize word. CSS capitalization transform gets messed up by highlights.
          word[0] = word[0].toUpperCase();
        }

        if (chars_to_highlight.includes(word[position_index].toLowerCase())) {
          word[position_index] = String.prototype.concat("<span class='highlight'>" + word[position_index] + "</span>");
        }

      }
      word = word.join("");

      return word;
    },
    text_vowels(word) {
      // calculate number of vowels
      let vowel_count = word.meta.vc;

      if (vowel_count == 1) {
        return "1 vowel";
      } else {
        return `${vowel_count} vowels`
      }
    },
    text_consonants(word) {
      let consonant_count = word.meta.cc;

      if (consonant_count == 1) {
        return "1 consonant";
      } else {
        return `${consonant_count} consonants`
      }
    },
  }
}
</script>

<style lang="scss">
  .word-centered {
    transform: translate(-50%,-50%);
  }

  .result {
    width: 100%;
    padding: 16px;
  }

  .word-title {
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .word {
    font-family: $ff-serif;
    font-weight: 500;
  }

  span.highlight {
    color: inherit;
    @apply text-emerald-900;
    @apply dark:text-emerald-50;
    @apply bg-emerald-100;
    @apply dark:bg-emerald-900;
  }

  .word-score {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: $ff-sans-serif;
  }

  .word-meta {
    font-family: $ff-sans-serif;
  }
</style>