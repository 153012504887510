<template>
<div>


  <WordModalV2 :word="selected_word" ref="word_analysis_modal"></WordModalV2>

  <div class="results-bar z-50 bg-gray-50 dark:bg-gray-950 bg-opacity-60 dark:bg-opacity-60">
      
      <VButton label="Back" :action="go_back"></VButton>

      <select value="" v-model="sort_mode">
        <option value="" disabled>Sort by</option>
        <option value="a">Alphabet</option>
        <option value="ws">Word score</option>
        <option value="dv">Vowels</option>
        <option value="dc">Consonants</option>
      </select> 
  </div>
  
  <div class="max-w-screen-xl mx-auto">
    <div class="side-by-side flex flex-col md:flex-row gap-2 md:gap-8 mx-2">
      <!-- Analytics page -->
      <div class="analytics gap-2">

      <Transition name="slide-open">
        <template v-if="!loading_state">
            <Analysis></Analysis>
        </template>
      </Transition>


      </div>

      <!-- Results page -->
      <div class="results gap-2">
        <h1 class="text-xl font-bold">Results</h1>
        <div class="text-left">
          <template v-if="!loading_state">
            <p class="text-sm">{{ results.length }} 
              <template v-if="results.length == 1">match</template>
              <template v-else>matches</template></p>
          </template>
          <template v-else>
            <p class="text-sm">Searching...</p>
          </template>
        </div>

        <WordList></WordList>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VButton from '../components/Button.vue';
import WordList from "../components/results/WordList.vue";
import WordModalV2 from "../components/results/WordModal_V2.vue";

import Analysis from "../components/analysis/Analysis.vue";

export default {
  name: "Results",
  components: {
    VButton,
    WordList,
    WordModalV2,
    Analysis,
  },
  props: {
    // URL query params
    find: String,
    has: String,
    not: String,
  },
  data: function() {
    return {  
      loading_state: true,
      selected_word: null,
    }
  },
  created: function() {
    this.loading_state = true;

    // first, throw out the old results
    this.$store.commit("set_results", []);
    this.$store.commit("set_results_metadata", []);

    const parsed_query = {
      fixed_chars: undefined,
      included_chars: undefined,
      excluded_chars: undefined,
    }

    if (this.find) {
      // Parse
      parsed_query.fixed_chars = this.find.split("").map((c) => {
        if (this.$alphabet.is_alpha(c)) { 
          return c.toLowerCase();
        } else {
          return null;
        }
      })
      // validate
      if (parsed_query.fixed_chars.length != 5) {
        console.warn("Fixed letter search query invalid.")
      }
      for (const c of parsed_query.fixed_chars) {
        if (c != null && !this.$alphabet.is_alpha(c)) {
          console.warn("Fixed letter search query invalid.")
        }
      }
    }
    if (this.has) {
      // Parse
      parsed_query.included_chars = new Set(this.has.split(","));
      // Validate
      for (const c of parsed_query.included_chars) {
        if (!this.$alphabet.is_alpha(c)) {
          console.warn("Included letter search query invalid.")
        }
      }
    }
    if (this.not) {
      // Parse
      parsed_query.excluded_chars = new Set(this.not.split(","));
      // Validate
      for (const c of parsed_query.excluded_chars) {
        if (!this.$alphabet.is_alpha(c)) {
          console.warn("Included letter search query invalid.")
        }
      }
    }

    // check if query params differ from store params
    // if they differ, overwrite the store params

    // ad hoc helper functions
    const are_sets_equal = function(setA, setB) {
      if (setA.size !== setB.size) return false;
      for (var a of setA) if (!setB.has(a)) return false;
      return true;
    }
    const are_arrays_equal = function(arrA, arrB) {
      if (arrA === arrB) return true;
      if (arrA.length !== arrB.length) return false;
      if (arrA == null || arrB == null) return false;
      for (var i = 0; i < arrA.length; ++i) {
        if (arrA[i] !== arrB[i]) return false;
      }
      return true;
    }

    if (!are_arrays_equal(parsed_query.fixed_chars, this.$store.state.fixed_chars)) {
      for (let i = 0; i < 5; i++) {
        this.$store.commit("set_fixed", {
          position: i,
          char: parsed_query.fixed_chars[i],
        })
      }
    }

    if (parsed_query.excluded_chars) {
      if (!are_sets_equal(parsed_query.excluded_chars, this.$store.state.excluded_chars)) {
        this.$store.commit("clear_exclusions");
        for (const c of parsed_query.excluded_chars) {
          this.$store.commit("add_exclusion", c);
        }
      }
    }
    if (parsed_query.included_chars) {
      if (!are_sets_equal(parsed_query.included_chars, this.$store.state.included_chars)) {
        this.$store.commit("clear_inclusions");
        for (const c of parsed_query.included_chars) {
          this.$store.commit("add_inclusion", c);
        }
      }
    }
  },
  mounted: function() {
    // make sure page starts at top
    this.$nextTick(() => {
      
      window.scrollTo(0, 0); 
    })

    this.$store.dispatch("search").then(
      () => {
        this.loading_state = false;
      }
    )

    setTimeout(() => {
      let adsbygoogle;
      (adsbygoogle = window.adsbygoogle || []).push({});
    }, 2000);
  },
  computed: {
    results: function() {
      return this.$store.getters.results;
    },
    sort_mode: {
      get () {
        return this.$store.state.sort_mode;
      },
      set (value) {
        this.$store.commit('set_sort_mode', value);
        this.$store.dispatch('sort');
      }
    }
  },
  methods: {
    activate_tab(tab_index) {
      console.log("activating tab ", tab_index);
      this.active_tab = tab_index;
    },
    go_back() {
      // this.$router.go(-1)
      this.$router.push("/")
    },
  },
}
</script>

<style lang="scss">
.results-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    // min-width: 320px;
    // max-width: 500px;

    position: fixed;
    top: 0;
    left: 0;
    @apply px-2;
    @apply py-4;
    @apply sm:py-4;
    @apply sm:px-4;
    backdrop-filter: blur(8px);
    border-bottom: 1px solid var(--primary-border-color);

    select {
      -webkit-appearance: none; 
      -moz-appearance: none;
      appearance: none;
      color: var(--primary-text-color);
      background: var(--background);
      font-family: $ff-sans-serif;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 16px;
      border: 1px solid var(--primary-border-color);
      border-radius: 8px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02);
      transform: translate3d(0px, 0px, 0px);
      cursor: pointer;
      transition: all 0.5s ease;
    }

    @media(hover) {
      select:hover {
        border-color: var(--primary-border-hover-color);
        transform: translate3d(0px, -2px, 0px);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
      }
    }
    select:active {
      transform: translate3d(0px, 0px, 0px);
      transition: all 0.2s ease;
    }
  }

.side-by-side {
  padding-top: 85px; // compensate for result bar
}

.analytics {
  @apply p-2;
  @apply flex-1;
}

.results {
  @apply p-2;
  @apply flex-1;

  display: flex;
  flex-direction: column;

  width: 100%;

  min-width: 320px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;


  .no-matches {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 375px;
  }
}

.slide-open-enter-active,
.slide-open-leave-active {
  transition: all 0.5s ease-in-out 0.5s;
  overflow: hidden;
  opacity: 1;
}

.slide-open-enter-from,
.slide-open-leave-to {
  overflow: hidden;
  opacity: 0;
}
</style>