<template>
  <Modal ref="modal">
    <div class="flex flex-col gap-6">
      <div>
        <div class="flex flex-row gap-1 align-middle items-center text-gray-500 dark:text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
          <p class="text-sm font-semibold">Word analysis</p>
        </div>
        <div class="flex flex-row gap-4 items-center">
          <h2 class="modal-word text-4xl font-bold capitalize">{{ word_data.str_word }}</h2>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div>
          <h3 class="text-base font-semibold">Guess power score</h3>
          <p class="text-sm dark:text-gray-400 text-gray-600">The guess power score indicates the <b>maximum</b> number of remaining 5-letter word candidates you could eliminate by guessing this word .</p>
        </div>
        <div class="grid grid-cols-2 divide divide-x divide-gray-200 dark:divide-gray-800 items-center">
          <div class="p-1 flex place-content-center">
            <div class="text-center">
              <p class="text-4xl font-extralight">{{ word_data.num_score_pct }}%</p>
              <p class="text-sm font-semibold dark:text-gray-400 text-gray-600">Elimination rate</p>
            </div>
          </div>
          <div class="p-1 flex place-content-center">
            <div class="text-center"> 
              <p class="text-4xl font-extralight">{{ word_data.num_score }}</p>
              <p class="text-sm font-semibold dark:text-gray-400 text-gray-600">
                  <template v-if="word_data.num_score == 1">
                    Word you could eliminate
                  </template>
                  <template v-else>
                    Words you could eliminate
                  </template>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div>
          <h3 class="text-base font-semibold">Letters</h3>
          <p class="text-sm dark:text-gray-400 text-gray-600">See how many of each letter is left in play among remaining 5-letter words.</p>
        </div>
        <template v-for="letter_obj of table_letter_freq" :key="letter_obj.letter">
          <div class="flex flex-row gap-2">
            <div class="char-box char-active">
              {{ letter_obj.letter.toUpperCase() }} 
            </div>
            <div class="flex flex-col gap-2">
              <template v-if="is_letter_filtered(letter_obj.letter)">
                <div>
                  <div class="text-sm text-gray-500">
                    Already part of your search
                  </div>
                </div>
              </template>
              <template v-else>
                <div>
                  <div class="text-small font-semibold">
                    {{ letter_obj.word_freq }} 
                    <template v-if="letter_obj.word_freq == 1">
                      word
                    </template>
                    <template v-else>
                      words
                    </template>
                  </div>
                  <div class="text-xs text-gray-500">
                    where {{ letter_obj.letter.toUpperCase() }} appears anywhere within the word.
                  </div>
                </div>
                <div>
                  <div class="text-small font-semibold">
                    {{ letter_obj.pos_freq }}
                    <template v-if="letter_obj.pos_freq == 1">
                      word
                    </template>
                    <template v-else>
                      words
                    </template>
                  </div>
                  <div class="text-xs text-gray-500">
                    where {{ letter_obj.letter.toUpperCase() }} appears at this position.
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="flex flex-col gap-4">
        <div>
          <h3 class="text-base font-semibold">Non-consecutive bigrams</h3>
          <p class="text-sm dark:text-gray-400 text-gray-600">See often two letters appear together in their respective positions among remaining 5-letter words.</p>
        </div>
        <template v-for="NC2G_obj of nc2g_data.table_nc2g_freq" :key="NC2G_obj.nc2g">
          <div class="flex flex-row gap-2">
            <div class="flex flex-row gap-px"
              v-html="format_NC2G(NC2G_obj.nc2g)">
            </div>
            <div>
              <template v-if="is_NC2G_filtered(NC2G_obj.nc2g)">
                <span class="text-sm text-gray-500">Already part of your search</span>
              </template>
              <template v-else>
                <span class="text-sm" v-html="NC2G_occurrence(NC2G_obj.nc2g)"></span>
              </template>
            </div>
            
          </div>
        </template>
      </div>
    </div>
  </Modal>  
</template>

<script>
import Modal from "../Modal.vue";
import { mapGetters } from 'vuex'

export default {
  name: "WordModalV2",
  components: {
    Modal
  },
  props: {
    word: String,
  },
  data() {
    return {
      word_data: {
        str_word: "arise",
        arr_unique_letters: [],
        num_vowels: 0,
        num_consonants: 0,
        num_score: 0,
        num_score_pct: 0,
      },
      table_letter_freq: [], // { letter, word_freq, pos_freq }
      nc2g_data: {
        arr_nc2gs: [],
        table_nc2g_freq: [], // { nc2g, freq }
      }
    }
  },
  computed: {
    ...mapGetters([
      'is_letter_filtered',
    ]),
  },
  mounted() {

  },
  updated() {
    let word_result_obj = this.$store.getters.lookup_word(this.word);

    // Unpacking

    // Word data
    this.word_data.str_word = this.word;
    this.word_data.arr_unique_letters = word_result_obj.meta.unique;
    this.word_data.num_vowels = word_result_obj.meta.vc;
    this.word_data.num_consonants = word_result_obj.meta.cc;
    this.word_data.num_score = word_result_obj.score;
    this.word_data.num_score_pct = word_result_obj.score_pct;
    // NC2G data
    this.nc2g_data.arr_nc2gs = word_result_obj.meta.NC2Gs;

    // Lookups

    // Letter freqs
    this.table_letter_freq = []; // reset
    for (let pos = 0; pos < this.word.length; pos++) {
      let L = this.word[pos];
      let word_freq = this.$store.getters.lookup_word_letter_freq(L);
      let pos_freq = this.$store.getters.lookup_positional_letter_freq(L, pos);

      this.table_letter_freq.push({
        letter: L,
        word_freq: word_freq,
        pos_freq: pos_freq,
      })
    }

    // NC2G freqs
    this.nc2g_data.table_nc2g_freq = []; // reset
    for (const nc2g of this.nc2g_data.arr_nc2gs) {

      let nc2g_freq = this.$store.getters.lookup_nc2g_freq(nc2g);
      this.nc2g_data.table_nc2g_freq.push({
        nc2g: nc2g,
        freq: nc2g_freq,
      })
    }
    
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    is_NC2G_filtered: function(NC2G) {
      if (this.$store.state.results_metadata.explored_NC2Gs.includes(NC2G)) {
        return true;
      } else {
        return false;
      }
    },
    format_NC2G: function(NC2G) {
      NC2G = NC2G.split("").map((char) => {
        if (char != "?") {
          return `<div class='char-box char-active'>${char.toUpperCase()}</div>`;
        } else {
          return `<div class='char-box'> </div>`;
        }
      })
      
      return NC2G.join("");
    },
    NC2G_occurrence: function(NC2G) {
      let text;
      let occurrences = this.$store.state.results_metadata.NC2G_table[NC2G];
      let occurrence_rate = Math.round(occurrences / this.$store.state.results.length * 100) / 10;
      let text_word = occurrences != 1 ? "words" : "word";

      text = `${occurrences} ${text_word} (${occurrence_rate}%)`
      
      return text;
    }
  },
}
</script>

<style lang="scss">
.modal-word {
  font-family: $ff-serif;
}

.char-box {
  @apply text-xs;
  @apply h-6;
  @apply w-6;
  @apply border;
  @apply rounded-md;
  @apply border-gray-200;
  @apply dark:border-gray-800;
  @apply flex;
  @apply place-content-center;
  @apply items-center;
}

.char-active {
  @apply border-gray-500;
  @apply dark:border-gray-500;
}
</style>