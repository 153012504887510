<template>
  <div class="max-w-screen-xl mx-auto">
    <div class="
      p-6
      sm:px-8
      flex flex-row gap-1 items-center">
      <h1 class="text-base font-bold">WhatFiveLetters?</h1>
      <h2 class="text-base font-thin">A Dictionary for Wordle</h2>
    </div>
    <div class="
      gap-2
      flex flex-col
      sm:grid sm:grid-cols-2
    ">
      <div class="col-span-1 
      sm:p-8
      hidden sm:block">
        <p class="text-xl font-medium pb-4">Welcome to WhatFiveLetters, a search dictionary for the acclaimed game Wordle.</p>
        <ul class="
          flex
          flex-col
          gap-4
        ">
          <li>
            <h3 class="font-bold">Up your strategy</h3>
            <p>See how many letters are left in play and which words are the best to guess at every step.</p>
          </li>
          <li>
            <h3 class="font-bold">Don't miss the answer</h3>
            <p>This tool has only all possible Wordle answers. No more, no fewer.</p>
          </li>
        </ul>

      </div>
      <div class="col-span-1">
        <SearchPanelV2></SearchPanelV2>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPanelV2 from "../components/search/Search_V2.vue";

export default {
  name: 'SearchV2',
  components: {
    SearchPanelV2,
  },
  mounted() {
    // setTimeout(() => {
    //   let adsbygoogle;
    //   (adsbygoogle = window.adsbygoogle || []).push({});
    // }, 2000);
  }
}
</script>

<style lang="scss" scoped>

</style>
